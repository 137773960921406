/* If you need to add @import statements, do so up here */

 /* triggers frontend rebuilds */
/* @import "jit-refresh.css"; */
@import "_photoswipe.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

section a {
  text-decoration: underline;
  color: inherit !important;
}

section a:hover {
  color: black !important;
}

.intro {
  font-size: clamp(0.75rem, 1rem + 1vw, 2.66rem);
}

@media (min-width: 800px) {
  .intro {
    font-size: 1.5rem;
  }
}

@media (min-width: 800px) {
  .intro {
    font-size: 1.7rem;
  }
}
@media (min-width: 900px) {
  .intro {
    font-size: 1.9rem;
  }
}

@media (min-width: 1000px) {
  .intro {
    font-size: 2.3rem;
  }
}

@media (min-width: 1200px) {
  .intro {
    font-size: 2.66rem;
  }
}

.prose code:not([class]), .inline-code-block {
  background-color: rgba(175, 184, 193, 0.2);
  border-radius: 6px;
  line-height: 1;
  padding: 2px 4px;
}

.prose code::before, .prose code::after {
  content: "";
}

.torchlight.has-focus-lines .line:not(.line-focus) {
  filter: blur(.095rem);
  opacity: .4;
  transition: filter .35s,opacity .35s;
}

.torchlight.has-focus-lines:hover .line:not(.line-focus) {
  filter: blur(0);
  opacity: 1;
}

.prose a:not(:where([class~=not-prose])):hover {
  @apply bg-yellow-300/50
}
