@import "../../node_modules/photoswipe/dist/photoswipe.css";

.pswp__custom-caption {
  background: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  color: rgb(17, 24, 39);
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 8px 16px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}

.hidden-caption-content {
  display: none;
}
